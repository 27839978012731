<template>
    <div
      class="zy-chart-ring"
      :style="{ height: height }"
    >
      <canvas
        ref="charts"
        :width="this.canvas_width"
        :height="this.canvas_height"
      ></canvas>
    </div>
  </template>
  
  <script>
  import * as echarts from "echarts"
  export default {
    data() {
      return {
        canvas_width: 0,
        canvas_height: 0,
        myChart: null,
        autoPrams: {},
      }
    },
    props: {
      // 区块颜色顺序
      color: {
        type: Array,
        default: () => ["#66BBF9", "#FFB822", "#62FF85", "#007FF4"],
      },
      data: {
        type: Array,
        default: () => [],
      },
      //标题数组
      axis: {
        type: Array,
        default: () => [],
      },
      //柱状图方向，默认vertical为垂直，horizontal为横向
      direction: {
        type: String,
        default: "vertical",
      },
      height: {
        type: String,
        default: "214px",
      },
    },
    computed: {
      option() {
        const option = {
          tooltip: {
            trigger: "axis",
            show: true,
          },
          xAxis: {
            type: this.direction == "vertical" ? "category" : "value",
            data: this.axis,
            splitLine: {
              show: this.direction != "vertical",
              lineStyle: {
                color: "#fff",
                type: "dashed", //虚线
                dashOffset: 12,
                opacity: 0.2,
              },
            },
          },
          yAxis: {
            type: this.direction != "vertical" ? "category" : "value",
            data: this.axis,
            splitLine: {
              show: this.direction == "vertical",
              lineStyle: {
                color: "#fff",
                type: "dashed", //虚线
                dashOffset: 12,
                opacity: 0.2,
              },
            },
          },
          grid: {
            //设置边距
            left: "0px",
            right: this.direction == "vertical" ? "0px" : "20px",
            bottom: "10px",
            top: "40px",
            containLabel: true,
          },
          legend: {
            //图示
            top: 0,
            left: 0,
            itemWidth: 10,
            itemHeight: 10,
            textStyle: {
              fontSize: "14px",
              color: "#B1B1B1",
            },
            // icon: 'diamond'
            data: [
                {icon: 'diamond', name: '生产碳排放'},
                {icon: 'diamond', name: '运输碳排放'},
                {icon: 'diamond', name: '实测碳排放'},
                {icon: 'image://https://profile.csdnimg.cn/F/0/1/3_rudy_zhou', name: '预测碳排放'}
            ]
            // icon: 'path://M904 476H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8z'
          },
          color: this.color,
          series: [],
        }
        this.data.forEach((item) => {
          option.series.push({
            type: "bar",
            barWidth: "12px",
            ...item,
          })
        })
  
        return option
      },
    },
    mounted() {
      this.$nextTick(() => {
        this.canvas_width = this.$el.clientWidth
        this.canvas_height = this.$el.clientHeight
        this.initCharts()
      })
    },
    methods: {
      initCharts() {
        this.$nextTick(() => {
          if (!this.myChart) {
            var chartDom = this.$refs.charts
            var myChart = echarts.init(chartDom)
            myChart.setOption(this.option)
            this.myChart = myChart
          }
        })
      },
    },
  }
  </script>
  
  <style lang="less" scoped>
  .zy-chart-ring {
    position: relative;
    width: 100%;
    height: 100%;
    &-detail {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
  </style>
  